<template>
	<div class="listBox container">
		<goodsList :commodityData="commodityData"></goodsList>
		
		<!-- 分页 -->
		<page 
			:pagesData='pagesData'
			:limit="limit"
			@CurrentChange="getCurrentChange"
			@PrevClick='getPrevClick'
			@NextClick='getNextClick'>
		</page>
	</div>
</template>

<script>
	import goodsList from '../../components/goodsList.vue'
	import page from '../../components/page.vue'
	import { getBiddingList } from '../../axios/api.js'
	export default{
		data(){
			return{
				page: 1, //页码
				limit: 20, //显示条数,
				commodityData: '', //商品数据			
				pagesData: '', //分页
			}
		},
		components:{
			goodsList,
			page
		},
		created() {
			this.getBiddingList()
		},
		methods:{			
			// 点击上一页
			getPrevClick(data) {
				this.page = data
				this.getBiddingList()
			},
			// 点击下一页
			getNextClick(data) {
				this.page = data
				this.getBiddingList()
			},
			// 点击当前页
			getCurrentChange(data){
				this.page = data
				this.getBiddingList()
			},
			// 获取标的列表
			getBiddingList(){
				let that = this
				let data = {
					typeId: '', //标的类型
					province: '', //标的所在地
					city: '', //标的所在地
					entrust: '', //委托类型
					status: '', //标的状态
					page: this.page, //页码
					limit: this.limit, //显示条数
				}
				getBiddingList(data).then(function(res) {
					console.log('getBiddingList:', res.data)
					let data = res.data
					if (data.code == 0) {
						that.commodityData = data.result
						that.pagesData = data
					} else {
						that.$message({
							message: data.msg,
							type: 'error'
						})
					}
				})
				.catch(function(err) {
					console.log('err:', err)
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.listBox{
		padding: 0 0 30px;
	}
</style>
